<script>
export default {
  components: {},
  data() {
    return {}
  },
  async mounted() {},
  methods: {}
}
</script>

<template>
  <div class="admin_board_page">
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <h4>기업협력 신청</h4>
    </nav>

    <router-view></router-view>
  </div>
</template>

<style scoped>
/* 공지사항 게시판 페이지 스타일 */
.admin_board_page {
  display: flex; /* 하위 요소 가로 배치 */
  flex-direction: row; /* 배치 방향 설정 */
  flex-wrap: wrap; /* 줄넘김 처리. nowrap: 넘치면 삐져나감. wrap: 줄넘김 */
  justify-content: flex-start; /* 메인축 방향 정렬 */
  align-items: center; /* 수직축 방향 정렬 */
}
.admin_board_page > nav {
  width: 100%;
}
</style>
